import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import Logo from 'components/assets/logo.svg'
import { isConnected, isBusiness, isInvestor, isAngelInvestor } from 'utils/utils'
import { setModal } from 'store/actions/modal'

const LogoLink = styled.div`
  display: flex;
  padding: ${props => props.isCenter ? '0 0 0 80px' : '0 0 0 20px'};
  z-index: 5;
  cursor: pointer;

  @media (max-width: 767px) {
    padding: unset;
    margin-left: ${props => props.isCenter ? '0' : '50px'};
    max-width: 100%;
    ${props => props.isCenter ? 'justify-content: center;' : ''}
  }
`

function LogoComponent ({ isCenter }) {
  const user = useSelector((state) => state.user.value)
  const isC = isConnected(user)
  const isB = isBusiness(user)
  const isI = isInvestor(user)
  const isAI = isAngelInvestor(user)
  const router = useRouter()
  const dispatch = useDispatch()
  const notConfirmed = user?.kycStatus !== 'confirmed'

  const nav = () => {
    if (isC && isB) router.push('/add-deal')
    if (isC && isAI && notConfirmed) dispatch(setModal({ shouldBPro: true }))
    if (isC && isI) router.push('/deal-flow')
    else router.push('/')
  }

  return <LogoLink isCenter={isCenter} onClick={nav} title="Shareline: Invest in Private Tech Companies">
    <Logo width="200px" height="141px" alt="Shareline: Invest in Private Tech Companies" />
  </LogoLink>
}

export default LogoComponent

LogoComponent.propTypes = {
  isCenter: PropTypes.bool
}
