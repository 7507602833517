import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'

import { TYPOGRAPHY_18_13 } from 'theme/theme'
import { isConnected } from 'utils/utils'
const SignUpModal = dynamic(() => import('components/modals/signUp'), { ssr: false })
const Profile = dynamic(() => import('components/layouts/header/profile'), { ssr: false })

const Button = styled.div`
  height: 28px;
  background: ${props => props.signUp ? '#0bf' : '#fff'};
  border-radius: 5px;
  box-shadow: ${props => props.signUp ? 'none' : '0px 4px 12px rgba(0, 0, 0, 0.16)'};
  cursor: pointer;
`

const ButtonText = styled.div`
  ${TYPOGRAPHY_18_13}

  color: ${props => props.signUp ? '#fff' : '#A0A5B3'};
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 27px;
  margin-left: 27px;
  white-space: nowrap;
`

export function AnonLinks ({ open, setOpen }) {
  const toSignIn = () => window.open(`https://${window.location.hostname}/sign-in`, '_self')
  const triggerOpen = () => setOpen(true)

  return (
    <>
      <Button onClick={toSignIn} className="mr-10" data-test="signIn" signUp>
        <ButtonText signUp>
          Log In
        </ButtonText>
      </Button>
      <Button onClick={triggerOpen} data-test="signUp">
        <ButtonText>
          Sign Up
        </ButtonText>
      </Button>
      <SignUpModal open={open} setOpen={setOpen} />
    </>
  )
}

AnonLinks.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool
}

export default function Delayed ({ user, delay, open, setOpen, fallback }) {
  const [delayed, setDelayed] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay)
    return () => clearTimeout(timeout)
  }, [])

  return !delayed && isConnected(user)
    ? <Profile />
    : !delayed
        ? <AnonLinks open={open} setOpen={setOpen} />
        : delayed && fallback
}

Delayed.propTypes = {
  user: PropTypes.any,
  delay: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  fallback: PropTypes.any
}
