import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import { useSelector } from 'react-redux'

import useMedia from 'utils/hooks/useMedia'
import Meta from 'components/layouts/meta'
import { TYPOGRAPHY_16 } from 'theme/theme'
import Logo from 'components/layouts/header/logo'
import Delayed from 'components/layouts/homepage/headerLinks'
const Subscribe = dynamic(() => import('components/layouts/homepage/subscribe'))
const Footer = dynamic(() => import('components/layouts/homepage/footer'))

const LinksTopRight = styled.div`
  position: relative;
  display: flex;
  margin-right: 40px;
`

const HomeWrapper = styled.div`
  background: #fff;
`

const Banner = styled.div`
  ${TYPOGRAPHY_16}

  background: #c4c4c4;
  color: #646770;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  opacity: 0.8;
`

export default function Layout ({ title, path, image, description, hero, children }) {
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state.user.value)
  const isWide = useMedia({ minWidth: 767 })

  return (
    <HomeWrapper>
      <Meta title={title} path={path} image={image} description={description} />
      <div style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.16)', position: 'relative' }}>
        <header>
          { !isWide ? <Logo isCenter /> : null }
          <nav className="flex space-between v-center">
            { isWide ? <Logo isCenter /> : null }
            <LinksTopRight className="d-xs-none">
              <Delayed user={user} delay={500} open={open} setOpen={setOpen} fallback={null} />
            </LinksTopRight>
          </nav>
        </header>
        { hero }
      </div>
      <main>
        { children }
        <Subscribe />
      </main>
      <Footer />
      <Banner>These are high risk investments. You may not get back the amount you invested in these products.</Banner>
    </HomeWrapper>
  )
}

Layout.propTypes = {
  isSignedIn: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  hero: PropTypes.any,
  children: PropTypes.any
}
